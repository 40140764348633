import LAYOUT_CONST from "constant";

export const JWT_API = {
  secret: "SECRET-KEY",
  timeout: "1 days",
};

export const FIREBASE_API = {
  apiKey: "AIzaSyCYB0iyImxSeT6GwQs-1aXftMyWqo4DqC4",
  authDomain: "onsibo-crm.firebaseapp.com",
  projectId: "onsibo-crm",
  storageBucket: "onsibo-crm.appspot.com",
  messagingSenderId: "290641096073",
  appId: "1:290641096073:web:84f0681307fac2db2d8558",
  measurementId: "G-6BVLSKW4FR",
};

export const AUTH0_API = {
  client_id: "7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V",
  domain: "dev-w0-vxep3.us.auth0.com",
};

export const AWS_API = {
  poolId: "us-east-1_AOfOTXLvD",
  appClientId: "3eau2osduslvb7vks3vsh9t7b0",
};

export const BASE_PATH = "";

export const DASHBOARD_PATH = "/dashboard/default";
export const DASHBOARD_PATH_INITIATED = "/dashboard/default";


// export const API_URL = "https://onsibo-crm-api-4khsnxmgoa-ue.a.run.app/api/v0/";

// export const API_URL = "https://onsibo-crm-api-puhrijwhqq-ue.a.run.app/api/v0/";
// export const API_URL = "https://onsibo-crm-api-puhrijwhqq-uc.a.run.app/api/v0/";
export const API_URL = "https://onsibo.uc.r.appspot.com/api/v0/";
// export const API_URL = "http://127.0.0.1:8180/api/v0/";



export const FBGRAPH_URL = "https://graph.facebook.com/v16.0/";

// xsmtpsib-f12c60a9e20d18c4ada832fb7e7baf10c022c8cc5e18ea415d2d50481e876b84-XCy1gJjGncAVp02r
// SMTP KEY

// onsibo key brevo:
// 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


export const HORIZONTAL_MAX_ITEM = 6;

const timeOfDay = () => {
  const now = new Date();
  const hour = now.getHours();
  const isDayTime = hour >= 6 && hour < 18;
  return isDayTime ? "light" : "light";
};

const paletteMode = timeOfDay();

export const statusList = [
  { value: "0", label: "Draft" },
  { value: "1", label: "Sent" },
  { value: "2", label: "Open" },
  { value: "3", label: "Revised" },
  { value: "4", label: "Declined" },
  { value: "5", label: "Accepted" },
];


export const ProjectStatusList = [
  { value: 0, label: "Not started" },//open
  { value: 1, label: "Started" }, // in progress
  { value: 2, label: "In progress" }, // in progress
  { value: 3, label: "Waiting" }, // waiting
  { value: 4, label: "Cancelled" }, 
  { value: 5, label: "Complete" },    //complete
];


export const categoryList = [
  { value: 1, label: "Gas" },
  { value: 2, label: "Toll road" },
  { value: 3, label: "Insurance" },
  { value: 4, label: "Cell" },
  { value: 5, label: "Utilities" },
  { value: 6, label: "Meals and entertainment" },
  { value: 7, label: "Supplies" },
  { value: 8, label: "Office expenses" },
  { value: 9, label: "Advertising" },
  { value: 10, label: "Sales taxes" },
  { value: 11, label: "Payroll" },
];


export const accountList = [
  { value: 0, label: "Account 1" },  
  { value: 1, label: "Account 2" },  
];


export const orderPriorityList = [
  { value: "0", label: "Low" },
  { value: "1", label: "Medium" },
  { value: "2", label: "High" },  
];

  //     0: "Draft",
  //     1: "Sent",
  //     2: "Open",
  //     3: "Revised",
  //     4: "Declined",
  //     5: "Accepted",

const config = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: `'DM Sans', sans-serif`,
  borderRadius: 3,
  outlinedFilled: true,
  // navType: 'dark', // light, dark
  navType: paletteMode, // light, dark auto by day hours
  presetColor: "theme6", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: "es", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: true,
};


export function pushLocalStorage(value,listName) {  
  let listaActual = localStorage.getItem(listName);  
  if (!listaActual) {
      listaActual = [];
  } else {      
      listaActual = JSON.parse(listaActual);
  }  
  listaActual.push(value);
  localStorage.setItem(listName, JSON.stringify(listaActual));
}

export function getLocalStorageList(listName) {
  const lista = localStorage.getItem(listName);
  if (lista) {
      return JSON.parse(lista);
  } else {
      return [];
  }
}

export function deleteValueFromLocal(key, value, listName) {
  let listaActual = localStorage.getItem(listName);
  if (!listaActual) {
    return; // Si no hay lista, no hacemos nada.
  }

  listaActual = JSON.parse(listaActual);

  // Filtramos la lista eliminando los elementos que coinciden con el criterio
  const listaActualizada = listaActual.filter(item => item[key] !== value);

  // Guardamos la lista actualizada en localStorage
  localStorage.setItem(listName, JSON.stringify(listaActualizada));
}


export default config;
